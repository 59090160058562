<template>
    <div class="width-full h-screen fixed top-0 right-0 flex items-center justify-center">
        <Card class="flex px-4 flex-col max-w-md" style="width: 400px">
            <div class="w-full py-4" style="border-bottom: 1px dashed #878e99">
                <p class="font-bold">New Criteria</p>
            </div>
            <div class="col-span-3 mt-4">
                <c-text
                    placeholder="--Enter--"
                    variant="w-full"
                    v-model="newCriteria"
                    label="Criteria Name"
                />
            </div>
            <div class="flex my-6 justify-end">
                <Button
                    background-color="none"
                    class="text-flame border border-solid border-dynamicBackBtn"
                    width="96px"
                    :disabled="disableAddBtn"
                    @click="closeModal"
                >
                    Cancel
                </Button>
                <Button
                    width="96px"
                    class="ml-4 text-white bg-dynamicBackBtn"
                    @click="submitCriteria"
                    :disabled="disableAddBtn"
                >
                    {{ disableAddBtn ? 'Saving...' : 'Save' }}
                </Button>
            </div>
        </Card>
    </div>
</template>
<script>
export default {
    name: "AddCriteria",
    components: {
        CText: () => import("@scelloo/cloudenly-ui/src/components/text"),
        Card: () => import("@/components/Card"),
        Button: () => import("@/components/Button"),
    },
    props: {
        disableAddBtn: Boolean
    },
    data() {
        return {
            isSubmitting: false,
            newCriteria: ''
        }
    },
    methods: {
        closeModal(){
            this.$emit("cancel");
        },
        submitCriteria(){
            this.$emit("add-criteria", this.newCriteria)
        }
    }
};
</script>
<style scoped>
.width-full{
    width: 38.5rem;
    z-index: 9999 !important;
    background: rgba(0, 0, 0, .5) !important;
}
</style>
